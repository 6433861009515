import { Popover } from '@headlessui/react';
import Filters from './filters/Filters';
import Places from './Places';

export default function Search({
  filters,
  setUserPosition,
  onFilterChange,
  activeFilterIds,
  primaryFilter,
}) {
  return (
    <div className="relative">
      <div className="flex p-4  gap-4 ">
        <div className="flex-1 ">
          <Places setUserPosition={setUserPosition} />
        </div>
        <Popover>
          <Popover.Button className="bg-nfnBlue-light text-white rounded-md p-2">
            <svg
              className="w-8 h-8"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.875 21.875C12.254 21.875 11.75 21.371 11.75 20.75C11.75 20.129 12.254 19.625 12.875 19.625C13.496 19.625 14 20.129 14 20.75C14 21.371 13.496 21.875 12.875 21.875ZM24.125 19.625H16.0419C15.5761 18.3189 14.3398 17.375 12.875 17.375C11.4102 17.375 10.1739 18.3189 9.70813 19.625H3.875C3.25288 19.625 2.75 20.1279 2.75 20.75C2.75 21.3721 3.25288 21.875 3.875 21.875H9.70813C10.1739 23.1811 11.4102 24.125 12.875 24.125C14.3398 24.125 15.5761 23.1811 16.0419 21.875H24.125C24.7471 21.875 25.25 21.3721 25.25 20.75C25.25 20.1279 24.7471 19.625 24.125 19.625ZM21.875 15.125C21.254 15.125 20.75 14.621 20.75 14C20.75 13.379 21.254 12.875 21.875 12.875C22.496 12.875 23 13.379 23 14C23 14.621 22.496 15.125 21.875 15.125ZM21.875 10.625C20.4102 10.625 19.1739 11.5689 18.7081 12.875H3.875C3.25288 12.875 2.75 13.3779 2.75 14C2.75 14.6221 3.25288 15.125 3.875 15.125H18.7081C19.1739 16.4311 20.4102 17.375 21.875 17.375C23.7358 17.375 25.25 15.8608 25.25 14C25.25 12.1392 23.7358 10.625 21.875 10.625ZM8.375 6.125C8.996 6.125 9.5 6.629 9.5 7.25C9.5 7.871 8.996 8.375 8.375 8.375C7.754 8.375 7.25 7.871 7.25 7.25C7.25 6.629 7.754 6.125 8.375 6.125ZM3.875 8.375H5.20812C5.67387 9.68113 6.91025 10.625 8.375 10.625C9.83975 10.625 11.0761 9.68113 11.5419 8.375H24.125C24.7471 8.375 25.25 7.87212 25.25 7.25C25.25 6.62788 24.7471 6.125 24.125 6.125H11.5419C11.0761 4.81887 9.83975 3.875 8.375 3.875C6.91025 3.875 5.67387 4.81887 5.20812 6.125H3.875C3.25288 6.125 2.75 6.62788 2.75 7.25C2.75 7.87212 3.25288 8.375 3.875 8.375Z"
                fill="currentColor"
              />
            </svg>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 bg-white w-full drop-shadow-md px-4 py-4 top-full left-0">
            {/* filters - popover */}
            <Filters
              filters={filters}
              onFilterChange={onFilterChange}
              activeFilterIds={activeFilterIds}
              primaryFilter={primaryFilter}
            />
          </Popover.Panel>
        </Popover>
      </div>
    </div>
  );
}
