import { useTranslation } from 'react-i18next';
import { cardLockFilters, retailFilters } from '../../../utils/map';

export default function Filters({
  filters,
  activeFilterIds,
  onFilterChange,
  primaryFilter,
}) {
  const { t } = useTranslation();
  return (
    <fieldset>
      <legend className="mb-4 text-lg font-bold text-center uppercase text-nfnGreen-dark">
        {t('FILTER BY AMENITIES')}
      </legend>
      <div className="grid grid-cols-2 gap-4 ">
        {filters
          .filter((filter) => {
            if (primaryFilter === 'CardLock') {
              return cardLockFilters.find((f) => f === filter.value);
            }
            if (primaryFilter === 'Retail') {
              return retailFilters.find((f) => f === filter.value);
            }
            if (primaryFilter === 'All Locations') {
              return true;
            }
            return false;
          })
          .map((filter) => (
            <div
              key={filter.id}
              className="relative flex items-start pb-4 border-b">
              <div className="flex items-center h-5 mr-3">
                <input
                  id={`filter-${filter.id}`}
                  name={`filter-${filter.id}`}
                  type="checkbox"
                  checked={activeFilterIds.includes(filter.id)}
                  onChange={() => {
                    onFilterChange(filter.id);
                  }}
                  className="w-4 h-4 border-gray-300 rounded focus:ring-nfnGreen-dark text-nfnGreen-dark"
                />
              </div>
              <div className="min-w-0 text-sm">
                <label
                  htmlFor={`filter-${filter.id}`}
                  className="font-medium select-none">
                  {t(filter.value)}
                </label>
                ``
              </div>
            </div>
          ))}
      </div>
    </fieldset>
  );
}
